<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 固定消息 </template>
      <template #input>
        <a-input v-model="name" placeholder="请输入关键字" />
        <a-select placeholder="选择消息类型" style="width: 200px;height: 29px;" v-model="msgType" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <a-select-option :value="1"> 系统消息 </a-select-option>
          <a-select-option :value="2"> 学习消息 </a-select-option>
          <a-select-option :value="3"> 人工消息 </a-select-option>
        </a-select>
        <a-button type="primary" class="btn" :loading="loading" @click="search()">搜索</a-button>
        <a-button class="all_boder_btn" @click="toDetail(1)">新建</a-button>
      </template>
    </HeaderBox>

    <a-table class="table-template" :rowKey="item => item.defaultMsgId" :columns="columns" :data-source="tableData"
      @change="onPage" :loading="loading" :scroll="{ x: 1500 }" :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        current: pageNumber,
      }">
      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>
      <template slot="msgType" slot-scope="item">
        {{ item == 1 ? "系统消息" : item == 2 ? "学习消息" : "人工消息" }}
      </template>
      <template slot="viewType" slot-scope="item">
        {{ item == 0 ? "不跳转页面" : item == 1 ? "考试确认信息页" : item == 2 ? "课程考试报名页" : item == 3 ? "课程详情页" : item == 4 ?
          "课程观看页" : item == 5 ? "直播详情页" : item == 6 ? "学习首页" : item == 7 ? "学习打卡页" : item == 8 ? "完善密码也" : item == 9 ?
            "身份认证页" : item == 10 ? "个人证书页" : item == 11 ? "设置页" : item == 12 ? "发票列表页" : item == 13 ? "活动详情页" : item == 14 ?
              "直播详情" : item == 15 ? "商品栏目" : item == 16 ? "资讯详情" : item == 17 ? "图书教具详情" : "不跳转" }}
      </template>
      <template slot="appmsgFlag" slot-scope="item">
        {{ item == 1 ? "是" : item == 0 ? "否" : "-" }}
      </template>
      <template slot="pushTime" slot-scope="item">
        {{ item ? item : "-" }}
      </template>
      <template slot="operation" slot-scope="item, row">
        <span class="blueText">
          <span @click="toDetail(3, row.defaultMsgId)">编辑</span>
          <span> | </span>
          <span @click="toDetail(2, row.defaultMsgId)">推送详情</span>
          <span> | </span>
          <span @click="fetchRecords(row)">推送记录</span>
        </span>
      </template>
    </a-table>

    <a-modal v-model="edit" width="1200px" title="推送记录" :centered="true" :closable="false">
      <div class="all_content_box">
        <a-table class="table-template" :rowKey="item => item.msgPushId" :columns="recordsColumns"
          :data-source="recordsTableData" @change="recordsOnPage" :loading="recordsLoading" :scroll="{ x: 1200 }"
          :pagination="{
            total: recordstotal,
            showTotal: (res) => `共${recordstotal}条`,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
          }">
          <template slot="index" slot-scope="item, row, index">
            {{ (recordsPageNumber - 1) * recordsPageSize + index + 1 }}
          </template>
          <template slot="pushStatus" slot-scope="item">
            {{ item == 1 ? "已推送" : "未推送" }}
          </template>
          <template slot="appmsgFlag" slot-scope="item">
            {{ item == 1 ? "是" : item == 0 ? "否" : "-" }}
          </template>
          <template slot="taskTime" slot-scope="item">
            {{ item ? item : "-" }}
          </template>
          <template slot="pushTime" slot-scope="item">
            {{ item ? item : "-" }}
          </template>
        </a-table>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="editCancel">好的</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "消息标题",
    align: "center",
    dataIndex: "title",
  }
  , {
    title: "消息内容",
    align: "center",
    dataIndex: "content",
  },
  {
    title: "消息分类",
    align: "center",
    dataIndex: "msgType",
    scopedSlots: {
      customRender: "msgType",
    },
  },
  {
    title: "推送场景",
    align: "center",
    dataIndex: "scene",
  },
  {
    title: "推送对象及触发条件",
    align: "center",
    dataIndex: "rule",
  },
  {
    title: "跳转页面",
    align: "center",
    dataIndex: "viewType",
    scopedSlots: {
      customRender: "viewType",
    },
  },
  {
    title: "是否APP推送",
    align: "center",
    dataIndex: "appmsgFlag",
    scopedSlots: {
      customRender: "appmsgFlag",
    },
  },
  {
    title: "定时推送时间",
    align: "center",
    dataIndex: "pushTime",
    scopedSlots: {
      customRender: "pushTime",
    },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
const recordsColumns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "用户手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "消息标题",
    align: "center",
    dataIndex: "title",
  }
  , {
    title: "消息内容",
    align: "center",
    dataIndex: "content",
  },
  {
    title: "推送状态",
    align: "center",
    dataIndex: "pushStatus",
    scopedSlots: {
      customRender: "pushStatus",
    },
  },
  {
    title: "是否APP推送",
    align: "center",
    dataIndex: "appmsgFlag",
    scopedSlots: {
      customRender: "appmsgFlag",
    },
  },
  {
    title: "推送时间",
    align: "center",
    dataIndex: "pushTime",
    scopedSlots: {
      customRender: "pushTime",
    },
  },
  {
    title: "定时推送时间",
    align: "center",
    dataIndex: "taskTime",
    scopedSlots: {
      customRender: "taskTime",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {
    HeaderBox,
  }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      name: "",
      msgType: undefined,
      loading: false,
      columns,
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      edit: false,

      recordsColumns,
      recordsLoading: false,
      recordsTableData: [],
      recordstotal: 0,
      recordsPageNumber: 1, //页码
      recordsPageSize: 10, // 页数
      currMsgId: undefined, // 当前点击消息id
    };
  }, // 事件处理器
  methods: {
    editCancel(e) {
      this.edit = false;
    },
    toDetail(operation, id) {
      this.$router.push(
        "/admin/MessageManage/newUserSMS?" +
        "userType=1" +
        "&operation=" +
        operation +
        "&id=" +
        id
      );
    },
    // 分页
    onPage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/default/msg/manage",
        method: "get",
        params: {
          current: this.pageNumber,
          size: this.pageSize,
          keyWord: this.name,
          msgType: this.msgType
        },
      }).then((res) => {
        this.loading = false
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },

    fetchRecords(e) {
      this.currMsgId = e.defaultMsgId;
      this.recordsPageNumber = 1;
      this.recordsPageSize = 10;
      this.getrecordsdataList();
    },
    recordsOnPage(e) {
      this.recordsPageNumber = e.current;
      this.recordsPageSize = e.pageSize;
      this.getrecordsdataList();
    },
    getrecordsdataList() {
      this.recordsLoading = true
      this.$ajax({
        url: "/hxclass-management/default/msg/getMagPushRecordList",
        method: "GET",
        params: {
          pageNum: this.recordsPageNumber,
          pageSize: this.recordsPageSize,
          msgId: this.currMsgId,
        },
      }).then((res) => {
        this.recordsLoading = false
        if (res.code == 200 && res.success) {
          this.recordsTableData = res.data.records;
          this.recordstotal = res.data.total;
          this.edit = true;
        } else {
          this.$message.error(res.message);
        }
        
      });
    }
  }, // 生命周期-实例创建完成后调用
  created() { }, // 生命周期-实例挂载后调用
  mounted() {
    this.getdataList();
  }, // 生命周期-实例销毁离开后调用
  destroyed() { }, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less"  scoped>
.all_main_wrap {
  min-height: 100%;

  .btn {
    margin: 0 24px 14px 0;
  }
}

.all_content_box {

  textarea.ant-input,
  .all_input {
    width: 340px;
  }

  textarea.ant-input {
    height: 120px;
  }
}

.all_left_name {
  width: 70px;
  text-align: right;
}

/deep/.ant-modal-header {
  border-bottom: none;
}

/deep/.ant-modal-title {
  text-align: center;
  font-size: 18px;
  color: #333;
}

/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}

/deep/ .ant-modal-body {
  padding: 14px 24px 14px;
}
</style>
